var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "up_image_model",
    },
    [
      !_vm.disabled
        ? _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.showImage.length >= _vm.num,
              },
              on: { click: _vm.onUpImage },
            },
            [_vm._v(" 点击上传 ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "up_image_box" },
        [
          _c(
            "draggable",
            {
              staticClass: "drag-box",
              attrs: {
                element: "div",
                "force-fallback": true,
                disabled: _vm.disabled,
                animation: 100,
              },
              on: { sort: _vm.onChangeSort },
              model: {
                value: _vm.showImage,
                callback: function ($$v) {
                  _vm.showImage = $$v
                },
                expression: "showImage",
              },
            },
            _vm._l(_vm.showImage, function (item, i) {
              return _c(
                "div",
                { key: i, staticClass: "up_image_line" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "up_image",
                      staticStyle: { width: "100px" },
                      attrs: { src: item, "preview-src-list": _vm.showImage },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-loading",
                          attrs: { slot: "placeholder" },
                          slot: "placeholder",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-loading",
                            staticStyle: { "font-size": "20px" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  !_vm.disabled
                    ? _c(
                        "div",
                        {
                          staticClass: "clear_icon",
                          on: {
                            click: function ($event) {
                              return _vm.clearImage(i)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-circle-close" })]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("input", {
        ref: "upload",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          multiple: _vm.num > 1,
          accept: "image/gif,image/jpeg,image/jpg,image/png",
        },
        on: {
          change: function ($event) {
            return _vm.changeImage($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }